import React, { Suspense } from "react";
import {createRoot} from 'react-dom/client';
import "./assets/scss/style.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
// import Loader from "./layouts/loader/Loader";
import { Provider } from "react-redux";
import store from "./store/store";
import 'material-icons/iconfont/material-icons.css';

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

root.render(
  <Provider store={store}>
  {/* <Suspense fallback={<Loader />}> */}
  <Suspense> 
    <HashRouter>
      <App />
    </HashRouter>
  </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
