import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../authService.js';
import { BASE_URL } from '../../axiosConfig';

const INITIAL_STATE = {
    mentors: [],
    mentor: {
        bio: "",
        github: "",
        linkedin: "",
        experience: 0,
        domain: "",
        payment_method: "free",
        country: "",
        state: "",
        phone: "",
        photo: "",
        user: ''
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations
export const fetchMentors = createAsyncThunk('mentor/fetchMentors', async (keywords = '') => {
    const response = await axios.get(`${BASE_URL}/mentor/mentors/`, {
        params: { keywords }
    });
    return response.data.data;
});

export const createMentor = createAsyncThunk('mentor/createMentor', async ({ formData, token }) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/mentor/mentor/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error creating mentor:', error.response);
        throw error;
    }
});

export const updateMentor = createAsyncThunk('mentor/updateMentor', async ({ id, mentorData, token }) => {
    try {
        const response = await axiosInstance.put(`${BASE_URL}/mentor/mentor/edit/${id}/`, mentorData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error updating mentor:', error.response);
        throw error;
    }
});

export const deleteMentor = createAsyncThunk('mentor/deleteMentor', async (id) => {
    await axiosInstance.put(`${BASE_URL}/mentor/mentor/delete/${id}/`);
    return id;
});

const mentorSlice = createSlice({
    name: 'mentor',
    initialState: INITIAL_STATE,
    reducers: {
        setBio: (state, action) => {
            state.mentor.bio = action.payload;
        },
        setGithub: (state, action) => {
            state.mentor.github = action.payload;
        },
        setLinkedin: (state, action) => {
            state.mentor.linkedin = action.payload;
        },
        setExperience: (state, action) => {
            state.mentor.experience = action.payload;
        },
        setDomain: (state, action) => {
            state.mentor.domain = action.payload;
        },
        setPaymentMethod: (state, action) => {
            state.mentor.payment_method = action.payload;
        },
        setCountry: (state, action) => {
            state.mentor.country = action.payload;
        },
        setState: (state, action) => {
            state.mentor.state = action.payload;
        },
        setPhone: (state, action) => {
            state.mentor.phone = action.payload;
        },
        setPhoto: (state, action) => {
            state.mentor.photo = action.payload;
        },
        setMentorData: (state, action) => {
            state.mentor = action.payload;
        },
        setUser: (state, action) => {
            state.user = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMentors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMentors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.mentors = action.payload;
            })
            .addCase(fetchMentors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createMentor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createMentor.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.mentors.push(action.payload);
            })
            .addCase(createMentor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateMentor.fulfilled, (state, action) => {
                const index = state.mentors.findIndex(mentor => mentor.id === action.payload.id);
                if (index !== -1) {
                    state.mentors[index] = action.payload;
                }
            })
            .addCase(deleteMentor.fulfilled, (state, action) => {
                state.mentors = state.mentors.filter(mentor => mentor.id !== action.payload);
            });
    }
});

export const {
    setCountry,
    setState,
    setPhoto,
    setBio,
    setPhone,
    setGithub,
    setLinkedin,
    setExperience,
    setDomain,
    setPaymentMethod,
    setMentorData,
    setPassword,
    setUser
} = mentorSlice.actions;

export default mentorSlice.reducer;
