import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';
import axiosInstance from '../../authService';

const INITIAL_STATE = {
    sellers: [],
    seller: {
        user: '',  // Corresponding to the CustomUser foreign key
        country: '',  // ForeignKey to Country
        state: '',  // ForeignKey to State
        photo: "",  // Image URL
        bio: "",  // Bio/Introduction
        phone: "",  // Phone Number
        git_hub: "",  // GitHub Profile URL
        linked_in: "",  // LinkedIn Profile URL
        skills: "",  // Skills
        is_deleted: false,
        is_blocked: false,
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations

// Fetch sellers
export const fetchSellers = createAsyncThunk('seller/fetchSellers', async () => {
    const response = await axios.get(`${BASE_URL}/market/projectsellers/`);
    return response.data.data;
});

// Create a new seller
export const createSeller = createAsyncThunk('seller/createSeller', async ({formData, token}) => {
    try {
        const response = await axios.post(`${BASE_URL}/market/projectseller/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error creating seller:', error.response);
        throw error;
    }
});

// Update a seller
export const updateSeller = createAsyncThunk('seller/updateSeller', async ({ sellerData }) => {
    const response = await axiosInstance.put(`${BASE_URL}/market/projectseller/edit/`, sellerData);
    return response.data.data;
});

// Delete a seller
export const deleteSeller = createAsyncThunk('seller/deleteSeller', async (id) => {
    await axios.put(`${BASE_URL}/market/projectseller/delete/${id}/`);
    return id;
});

const sellerSlice = createSlice({
    name: 'seller',
    initialState: INITIAL_STATE,
    reducers: {
        setUser: (state, action) => {
            state.seller.user = action.payload;
        },
        setCountry: (state, action) => {
            state.seller.country = action.payload;
        },
        setState: (state, action) => {
            state.seller.state = action.payload;
        },
        setPhoto: (state, action) => {
            state.seller.photo = action.payload;
        },
        setBio: (state, action) => {
            state.seller.bio = action.payload;
        },
        setPhone: (state, action) => {
            state.seller.phone = action.payload;
        },
        setGitHub: (state, action) => {
            state.seller.git_hub = action.payload;
        },
        setLinkedIn: (state, action) => {
            state.seller.linked_in = action.payload;
        },
        setSkills: (state, action) => {
            state.seller.skills = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.seller.is_deleted = action.payload;
        },
        setIsBlocked: (state, action) => {
            state.seller.is_blocked = action.payload;
        },
        setSellerData: (state, action) => {
            state.seller = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSellers.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchSellers.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sellers = action.payload;
            })
            .addCase(fetchSellers.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createSeller.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createSeller.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.sellers.push(action.payload);
            })
            .addCase(createSeller.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateSeller.fulfilled, (state, action) => {
                const index = state.sellers.findIndex(seller => seller.id === action.payload.id);
                if (index !== -1) {
                    state.sellers[index] = action.payload;
                }
            })
            .addCase(deleteSeller.fulfilled, (state, action) => {
                state.sellers = state.sellers.filter(seller => seller.id !== action.payload);
            });
    }
});

// Export actions and reducer
export const {
    setUser,
    setCountry,
    setState,
    setPhoto,
    setBio,
    setPhone,
    setGitHub,
    setLinkedIn,
    setSkills,
    setIsDeleted,
    setIsBlocked,
    setSellerData
} = sellerSlice.actions;

export default sellerSlice.reducer;