import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../authService';
import { BASE_URL } from '../../axiosConfig';

// Initial State for Marketplace Projects
const INITIAL_STATE = {
    marketplaceProjects: [],
    sellerMarketplaceProjects: [], // New state for seller-specific projects
    project: {
        seller: '',  // ForeignKey to ProjectSeller
        title: '',
        description: '',
        price: '',
        video_url: '',
        image: '',
        tech_stack: '',
        demo_link: '',
        is_sold: false,
        is_verified: false,
        is_deleted: false
    },
    status: 'idle',
    sellerStatus: 'idle', // New status for seller-specific projects
    error: null,
    purchaseStatus: 'idle',
    purchaseError: null
};

// Async Actions for Marketplace Projects

// Fetch Marketplace Projects
export const fetchMarketplaceProjects = createAsyncThunk('project/fetchMarketplaceProjects', async () => {
    const response = await axios.get(`${BASE_URL}/market/marketplace-projects/`);
    return response.data.data;
});

// Fetch Seller-Specific Marketplace Projects
export const fetchSellerMarketplaceProjects = createAsyncThunk('project/fetchSellerMarketplaceProjects', async (token) => {
    const response = await axiosInstance.get(`${BASE_URL}/market/seller/projects/`, {
        headers: {
            'Authorization': `Bearer ${token}`,
        }
    });
    return response.data.data;
});

// Fetch a specific Marketplace Project by ID
export const fetchMarketplaceProjectById = createAsyncThunk(
    'project/fetchMarketplaceProjectById',
    async (id) => {
      const response = await axios.get(`${BASE_URL}/market/marketplace-project/view/${id}/`);
      return response.data.data; // Assuming the project details are in `data.data`
    }
  );
  

// Create a New Marketplace Project
export const createMarketplaceProject = createAsyncThunk('project/createMarketplaceProject', async ({ formData, token }) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/market/marketplace-project/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error creating project:', error.response);
        throw error;
    }
});

// Update a Marketplace Project
export const updateMarketplaceProject = createAsyncThunk('project/updateMarketplaceProject', async ({ id, projectData }) => {
    const response = await axiosInstance.put(`${BASE_URL}/market/marketplace-project/edit/${id}/`, projectData);
    return response.data.data;
});

// Delete a Marketplace Project
export const deleteMarketplaceProject = createAsyncThunk('project/deleteMarketplaceProject', async (id) => {
    await axiosInstance.put(`${BASE_URL}/market/marketplace-project/delete/${id}/`);
    return id;
});

// New action for initiating a purchase
export const purchaseProject = createAsyncThunk(
    'project/purchaseProject',
    async ({ projectId, token }) => {
        const response = await axiosInstance.post(`${BASE_URL}/market/purchase/${projectId}/`, {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data;
    }
);

// Marketplace Project Slice Implementation
const marketplaceProjectSlice = createSlice({
    name: 'marketplace',
    initialState: INITIAL_STATE,
    reducers: {
        setSeller: (state, action) => {
            state.project.seller = action.payload;
        },
        setTitle: (state, action) => {
            state.project.title = action.payload;
        },
        setDescription: (state, action) => {
            state.project.description = action.payload;
        },
        setPrice: (state, action) => {
            state.project.price = action.payload;
        },
        setVideoUrl: (state, action) => {
            state.project.video_url = action.payload;
        },
        setImage: (state, action) => {
            state.project.image = action.payload;
        },
        setTechStack: (state, action) => {
            state.project.tech_stack = action.payload;
        },
        setDemoLink: (state, action) => {
            state.project.demo_link = action.payload;
        },
        setIsSold: (state, action) => {
            state.project.is_sold = action.payload;
        },
        setIsVerified: (state, action) => {
            state.project.is_verified = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.project.is_deleted = action.payload;
        },
        setProjectData: (state, action) => {
            state.project = action.payload;
        },
        resetPurchaseStatus: (state) => {
            state.purchaseStatus = 'idle';
            state.purchaseError = null;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMarketplaceProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMarketplaceProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.marketplaceProjects = action.payload;
            })
            .addCase(fetchMarketplaceProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchSellerMarketplaceProjects.pending, (state) => {
                state.sellerStatus = 'loading';
            })
            .addCase(fetchSellerMarketplaceProjects.fulfilled, (state, action) => {
                state.sellerStatus = 'succeeded';
                state.sellerMarketplaceProjects = action.payload;
            })
            .addCase(fetchSellerMarketplaceProjects.rejected, (state, action) => {
                state.sellerStatus = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchMarketplaceProjectById.pending, (state) => {
                state.status = 'loading';
              })
              .addCase(fetchMarketplaceProjectById.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.project = action.payload; // Update the state with the fetched project
              })
              .addCase(fetchMarketplaceProjectById.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
              })
            .addCase(createMarketplaceProject.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createMarketplaceProject.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.marketplaceProjects.push(action.payload);
            })
            .addCase(createMarketplaceProject.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateMarketplaceProject.fulfilled, (state, action) => {
                const index = state.marketplaceProjects.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    state.marketplaceProjects[index] = action.payload;
                }
            })
            .addCase(deleteMarketplaceProject.fulfilled, (state, action) => {
                state.marketplaceProjects = state.marketplaceProjects.filter(project => project.id !== action.payload);
            })
            .addCase(purchaseProject.pending, (state) => {
                state.purchaseStatus = 'loading';
            })
            .addCase(purchaseProject.fulfilled, (state, action) => {
                state.purchaseStatus = 'succeeded';
                // You might want to update the project or user state here
                // depending on what your backend returns
            })
            .addCase(purchaseProject.rejected, (state, action) => {
                state.purchaseStatus = 'failed';
                state.purchaseError = action.error.message;
            });
    }
});

// Export Actions and Reducer
export const {
    setSeller,
    setTitle,
    setDescription,
    setPrice,
    setVideoUrl,
    setImage,
    setTechStack,
    setDemoLink,
    setIsSold,
    setIsVerified,
    setIsDeleted,
    setProjectData,
    resetPurchaseStatus
} = marketplaceProjectSlice.actions;

export default marketplaceProjectSlice.reducer;



