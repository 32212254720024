// src/redux/slices/userSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { BASE_URL } from '../../axiosConfig';
import axiosInstance from '../../authService';

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await axiosInstance.get(`${BASE_URL}/user/users/`);
  return response.data.data;
});


export const toggleBlockUser = createAsyncThunk('user/toggleBlockUser', async (id) => {
  const response = await axiosInstance.put(`${BASE_URL}/user/block-user/${id}/`);
  return response.data;
});


const initialState = {
  accessToken: null,
  refreshToken: null,
  roles: [],
  isAuthenticated: false,
  users: [],
  status: 'idle',
  error: null,
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess(state, action) {
      const { accessToken, refreshToken, roles } = action.payload;
      state.accessToken = accessToken;
      state.refreshToken = refreshToken;
      state.roles = roles;
      state.isAuthenticated = true;
    },
    logoutSuccess(state) {
      state.accessToken = null;
      state.refreshToken = null;
      state.roles = [];
      state.isAuthenticated = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(toggleBlockUser.fulfilled, (state, action) => {
        const index = state.users.findIndex(user => user.id === action.payload.id);
        if (index !== -1) {
          state.users[index].is_active = !state.users[index].is_active;
        }
      })
  },
});

export const { loginSuccess, logoutSuccess } = userSlice.actions;
export default userSlice.reducer;
