import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Container, Row, Col, Card, CardBody, CardTitle, Badge } from 'reactstrap';
import { fetchMarketplaceProjects } from '../../features/market/marketSlice';
import { Link } from 'react-router-dom';
import './AllMarketProjects.css'; // Make sure to create this CSS file


const AllMarketProjects = () => {
    const dispatch = useDispatch();
    const { marketplaceProjects, status, error } = useSelector((state) => state.marketplace);

    useEffect(() => {
        dispatch(fetchMarketplaceProjects());
    }, [dispatch]);

    const renderProjects = () => {
        if (status === 'loading') {
            return <p className="text-center">Loading projects...</p>;
        } else if (status === 'succeeded') {
            if (marketplaceProjects.length === 0) {
                return <p className="text-center">No marketplace projects available.</p>;
            } else {
                return marketplaceProjects.map((project) => (
                    <Col key={project.id} xs={12} md={6} className="mb-4">
                        <Card className="h-100 shadow-sm border project-card">
                            <CardBody className="d-flex flex-column">
                                <h5 className="card-title">{project.title}</h5>
                                <p className="card-text flex-grow-1">{project.description}</p>
                                <div className="mt-auto">
                                    <Badge color="info" pill className="mb-2">${project.price}</Badge>
                                    <p className="text-muted small mb-0">Sign in to your marketplace dashboard to view more and purchase.</p>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                ));
            }
        } else if (status === 'failed') {
            return <p className="text-center">Error fetching projects: {error}</p>;
        }
    };

    return (
        <Container fluid className="py-5">
            <Row className="justify-content-center">
                <Col xs={12} lg={10}>
                    <Card className="mb-4">
                        <CardBody>
                            <Link to="/" className="go-back-link">Go back to home</Link>
                            <CardTitle tag="h4" className="border-bottom p-3 mb-3">
                                <i className="bi bi-card-text me-2"></i>Marketplace Projects
                            </CardTitle>
                            <Row className="justify-content-center">
                                {renderProjects()}
                            </Row>
                            <div className="text-center mt-4">
                                <Link to="/marketplacelogin" className="btn btn-primary btn-lg">
                                    Sign In to Marketplace Dashboard
                                </Link>
                            </div>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

export default AllMarketProjects;
