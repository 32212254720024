import { element } from "prop-types";
import { lazy } from "react";
import AllMarketProjects from "../pages/market/AllMarketProjects.js";

// import JobDetail from "../pages/jobs/jobDetail.js";

/****Layouts*****/
const FullLayout = lazy(() => import("../layouts/FullLayout.js"));
const CompanionLayout = lazy(() => import("../layouts/companion/CompanionLayout.js"));
const MentorLayout = lazy(() => import("../layouts/mentor/MentorLayout.js"));
const HrLayout = lazy(() => import("../layouts/hr/HrLayout.js"));
const AdminLayout = lazy(() => import("../layouts/admin/AdminLayout.js"));
const ContributorLayout = lazy(() => import("../layouts/contributor/ContributorLayout.js"));
const MarketplaceLayout = lazy(() => import("../layouts/marketplace/MarketplaceLayout.js"));

/***** Pages ****/

const LandingPage = lazy(() => import("../pages/home/LandingPage.js"));
const SignUp = lazy(() => import("../pages/authentication/SignUp.js"));
const Login = lazy(() => import("../pages/authentication/Login.js"));
const Roles = lazy(() => import("../pages/authentication/Roles.js"));
const VerifyOTP = lazy(() => import("../pages/authentication/VerifyOTP.js"));
const ForgotPassword = lazy(() => import("../pages/authentication/ForgotPassword.js"));
const ResetPassword = lazy(() => import("../pages/authentication/resetPassword.js"));
const UserList = lazy(() => import("../pages/authentication/UsersList.js"));
const UserRequest = lazy(() => import("../pages/authentication/UserRequest.js"));
const Starter = lazy(() => import("../views/Starter.js"));
const About = lazy(() => import("../views/About.js"));
const Alerts = lazy(() => import("../views/ui/Alerts"));
const Badges = lazy(() => import("../views/ui/Badges"));
const Buttons = lazy(() => import("../views/ui/Buttons"));
const Cards = lazy(() => import("../views/ui/Cards"));
const Grid = lazy(() => import("../views/ui/Grid"));
const Tables = lazy(() => import("../views/ui/Tables"));
const Forms = lazy(() => import("../views/ui/Forms"));
const Breadcrumbs = lazy(() => import("../views/ui/Breadcrumbs"));

const JobList = lazy(() => import("../pages/jobs/JobList"));
const HrJobList = lazy(() => import("../pages/jobs/HrJobList"));
const JobCreate = lazy(() => import("../pages/jobs/JobCreate.js"));
const JobUpdate = lazy(() => import("../pages/jobs/JobUpdate"));
const JobDelete = lazy(() => import("../pages/jobs/JobDelete.js"));

const JobCards = lazy(() => import("../pages/jobs/JobCards.js"));
const JobDetail = lazy(() => import("../pages/jobs/jobDetail.js"));
const JobApplicants = lazy(() => import("../pages/jobs/JobApplicants.js"));
const JobsApplied = lazy(() => import("../pages/jobs/JobsApplied.js"));

const MainDashboard = lazy(() => import("../pages/dashboard/MainDashboard"));
const CompanionDashboard = lazy(() => import("../pages/dashboard/CompanionDashboard"));
const HrDashboard = lazy(() => import("../pages/dashboard/HrDashboard"));
const MentorDashboard = lazy(() => import("../pages/dashboard/MentorDashboard"));
const AdminDashboard = lazy(() => import("../pages/dashboard/AdminDashboard"));
const ContributorDashboard = lazy(() => import("../pages/dashboard/ContributorDashboard"));
const MarketplaceDashboard = lazy(() => import("../pages/dashboard/MarketplaceDashboard"));

const CompanionList = lazy(() => import("../pages/companions/CompanionList.js"));
const CompanionGridView = lazy(() => import("../pages/companions/CompanionGridView.js"));
const CompanionCreate = lazy(() => import("../pages/companions/CompanionCreate.js"));
const CompanionUpdate = lazy(() => import("../pages/companions/CompanionUpdate.js"));
const CompanionDelete = lazy(() => import("../pages/companions/CompanionDelete.js"));
const CompanionDetail = lazy(() => import("../pages/companions/CompanionDetail.js"));

const MentorList = lazy(() => import("../pages/mentor/MentorList.js"));
const MentorGridView = lazy(() => import("../pages/mentor/MentorGridView.js"));
const MentorCreate = lazy(() => import("../pages/mentor/MentorCreate.js"));
const MentorEdit = lazy(() => import("../pages/mentor/MentorUpdate.js"));
const MentorDelete = lazy(() => import("../pages/mentor/MentorDelete.js"));
const MentorDetail = lazy(() => import("../pages/mentor/MentorDetail.js"));
const MentorRequests = lazy(() => import("../pages/mentor/MentorRequests.js"));

const MyEvents = lazy(() => import("../pages/events/myEvents/MyEventList.jsx"));
const MyEventDetail = lazy(() => import("../pages/events/myEvents/MyEventDetail.js"));

const Events = lazy(() => import("../pages/events/EventList.js"));
const EventDetail = lazy(() => import("../pages/events/EventDetail.js"));
const EventCreate = lazy(() => import("../pages/events/EventCreate.js"));
const EventUpdate = lazy(() => import("../pages/events/EventUpdate.js"));
const EventDelete = lazy(() => import("../pages/events/EventDelete.js"));

const EventRegister = lazy(() => import("../pages/events/eventRegister/EventRegister.js"));
const RegisteredEvents = lazy(() => import("../pages/events/eventRegister/ListRegisterEvents.js"));

const HrList = lazy(() => import("../pages/hr/HrList.js"));
const HrCreate = lazy(() => import("../pages/hr/HrCreate.js"));
const HrEdit = lazy(() => import("../pages/hr/HrUpdate.js"));
const HrDelete = lazy(() => import("../pages/hr/HrDelete.js"));
const HrDetail = lazy(() => import("../pages/hr/HrDetail.js"));

const ContributorList = lazy(() => import("../pages/contributor/ContributorList.js"));
const ContributorCreate = lazy(() => import("../pages/contributor/ContributorCreate.js"));
const ContributorUpdate = lazy(() => import("../pages/contributor/ContributorUpdate.js"));
const ContributorDelete = lazy(() => import("../pages/contributor/ContributorDelete.js"));
const ContributorDetail = lazy(() => import("../pages/contributor/ContributorDetail.js"));

const ProjectList = lazy(() => import("../pages/project/ProjectList.js"));
const ProjectCreate = lazy(() => import("../pages/project/ProjectCreate.js"));
const ProjectUpdate = lazy(() => import("../pages/project/ProjectEdit.js"));
const ProjectDelete = lazy(() => import("../pages/project/ProjectDelete.js"));
const ProjectUI = lazy(() => import("../pages/project/ProjectListingUI.js"));
const ProjectDetail = lazy(() => import("../pages/project/ProjectDetail.js"));
const MyProjects = lazy(() => import("../pages/project/MyProjects.js"));

const CommentList = lazy(() => import("../pages/project/CommentList.js"));
const CommentCreate = lazy(() => import("../pages/project/CommentCreate.js"));
const CommentUpdate = lazy(() => import("../pages/project/CommentEdit.js"));
const CommentDelete = lazy(() => import("../pages/project/CommentDelete.js"));

const MarketplaceSignup = lazy(() => import("../pages/authentication/MarketplaceSignup.js"));
const MarketplaceLogin = lazy(() => import("../pages/authentication/MarketplaceLogin.js"));
const SellerCreate = lazy(() => import("../pages/market/SellerCreate.js"));
const SellerUpdate = lazy(() => import("../pages/market/SellerUpdate.js"));
const SellerDetail = lazy(() => import("../pages/market/SellerDetail.js"));
const MarketplaceProjects = lazy(() => import("../pages/market/AllMarketProjects.js"));
const MarketplaceProjectCreate = lazy(() => import("../pages/market/MarketplaceProjectCreate.js"));
const MarketplaceProjectUpdate = lazy(() => import("../pages/market/MarketplaceProjectUpdate.js"));
const MarketplaceProjectDetail = lazy(() => import("../pages/market/MarketplaceProjectDetail.js"));
const SellerProjectDetail = lazy(() => import("../pages/market/SellerProjectDetail.js"));
const SellerProjects = lazy(() => import("../pages/market/SellerMarketProjects.js"));
const MyMarketplaceProjects = lazy(() => import("../pages/market/MarketplaceProjects.js"));

const TaskList = lazy(() => import("../pages/task/TaskList.js"));
const TaskCreate = lazy(() => import("../pages/task/TaskCreate.js"));
const TaskUpdate = lazy(() => import("../pages/task/TaskUpdate.js"));
const TaskDelete = lazy(() => import("../pages/task/TaskDelete.js"));

const ContributorTaskList = lazy(() => import("../pages/contributor_task/ContributorTaskList.js"));
const ContributorTaskCreate = lazy(() => import("../pages/contributor_task/ContributorTaskCreate.js"));
const ContributorTaskUpdate = lazy(() => import("../pages/contributor_task/ContributorTaskUpdate.js"));
const ContributorTaskDelete = lazy(() => import("../pages/contributor_task/ContributorTaskDelete.js"));

// Add this new import at the top of the file with the other lazy imports:
const CheckoutPage = lazy(() => import("../pages/market/CheckoutPage.js"));

/*****Routes******/

const ThemeRoutes = [
  {
    path: "/",
    element: <LandingPage />,
    exact: true,
  },
  {
    path: "/signup",
    element: <SignUp />,
    exact: true,
  },
  {
    path: "/marketplacelogin",
    element: <MarketplaceLogin />,
    exact: true,
  },
  {
    path: "/market/marketplace_projects",
    element: <AllMarketProjects />,
    exact: true,
  },
  
  {
    path: "/login",
    element: <Login />,
    exact: true,
  },
  {
    path: "/roles",
    element: <Roles />,
    exact: true,
  },
  {
    path: "/verify-otp",
    element: <VerifyOTP />,
    exact: true,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    exact: true,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
    exact: true,
  },
  {
    path: "/dashboard/companion",
    element: <CompanionLayout />,
    children: [
      { path: "", element: <CompanionDashboard />, exact: true },
      { path: "jobs", element: <JobCards />, exact: true },
      { path: "job/view/:id", element: <JobDetail />, exact: true },
      { path: "job/applied", element: <JobsApplied />, exact: true },
      
      { path: "companions", element: <CompanionList />, exact: true },
      { path: "companion/create", element: <CompanionCreate />, exact: true },
      { path: "companion/update/:id", element: <CompanionUpdate />, exact: true },
      { path: "companions/grid", element: <CompanionGridView />, exact: true },
      { path: "companions/detail", element: <CompanionDetail />, exact: true },

      
      { path: "events", element: <Events />, exact: true },
      { path: "events/detail/:id", element: <EventDetail />, exact: true },

      { path: "events/:id/event-registration", element: <EventRegister />, exact: true },
      { path: "events/:id/event-registration-list", element: <RegisteredEvents />, exact: true },


      { path: "mentors", element: <MentorList />, exact: true },
      { path: "mentors/grid", element: <MentorGridView />, exact: true },

      { path: "hrs", element: <HrList />, exact: true },
      { path: "projects", element: <ProjectList />, exact: true },
      { path: "projects/create", element: <ProjectCreate />, exact: true },
      { path: "projects/update/:id", element: <ProjectUpdate />, exact: true },
      { path: "projects/delete", element: <ProjectDelete />, exact: true },
      { path: "projectui", element: <ProjectUI />, exact: true },      
      { path: "project/detail/:id", element: <ProjectDetail />, exact: true },
      { path: "myprojects", element: <MyProjects />, exact: true },
      
      // Comment routes
      { path: "comments", element: <CommentList />, exact: true },
      { path: "comments/create", element: <CommentCreate />, exact: true },
      { path: "comments/update/:id", element: <CommentUpdate />, exact: true },
      { path: "comments/delete", element: <CommentDelete />, exact: true }
    ],
  },
  {
    path: "/dashboard/mentor",
    element: <MentorLayout />,
    children: [
      { path: "", element: <MentorDashboard />, exact: true },
      { path: "jobs", element: <JobCards />, exact: true },

      { path: "companions/grid", element: <CompanionGridView />, exact: true },
      { path: "companions/detail", element: <CompanionDetail />, exact: true },

      { path: "events", element: <Events />, exact: true },
      { path: "events/detail", element: <EventDetail />, exact: true },
      { path: "events/create", element: <EventCreate />, exact: true },
      { path: "events/update/:id", element: <EventUpdate />, exact: true },
      { path: "events/delete", element: <EventDelete />, exact: true },

      { path: "my_events", element: <MyEvents />, exact: true },
      { path: "my_events/detail/:id", element: <MyEventDetail />, exact: true },
      { path: "my_events/create", element: <EventCreate />, exact: true },
      { path: "my_events/update/:id", element: <EventUpdate />, exact: true },
      { path: "my_events/delete", element: <EventDelete />, exact: true },


      { path: "mentors/grid", element: <MentorGridView />, exact: true },
      { path: "mentor/detail/", element: <MentorDetail />, exact: true },
      { path: "mentor/create", element: <MentorCreate />, exact: true },
      { path: "mentor/update/", element: <MentorEdit />, exact: true },
      { path: "mentor/requests/", element: <MentorRequests />, exact: true },

      { path: "hrs", element: <HrList />, exact: true },

      
      { path: "projects", element: <ProjectList />, exact: true },
      { path: "projectui", element: <ProjectUI />, exact: true },
      { path: "project/detail/:id", element: <ProjectDetail />, exact: true },

    ],
  },
  {
    path: "/dashboard/hr",
    element: <HrLayout />,
    children: [
      { path: "", element: <HrDashboard />, exact: true },

      { path: "companions", element: <CompanionList />, exact: true },
      { path: "companions/detail", element: <CompanionDetail />, exact: true },
      { path: "mentors/grid", element: <MentorGridView />, exact: true },
      { path: "companions/grid", element: <CompanionGridView />, exact: true },


      { path: "events", element: <Events />, exact: true },
      { path: "events/detail", element: <EventDetail />, exact: true },
      { path: "events/create", element: <EventCreate />, exact: true },
      { path: "events/update/:id", element: <EventUpdate />, exact: true },
      { path: "events/delete", element: <EventDelete />, exact: true },

      
      { path: "hr/create", element: <HrCreate />, exact: true },
      { path: "hr/update/", element: <HrEdit />, exact: true },
      { path: "hr/detail", element: <HrDetail />, exact: true },
      
      { path: "projectui", element: <ProjectUI />, exact: true },

      { path: "jobs", element: <JobList />, exact: true },
      { path: "myjobs", element: <HrJobList />, exact: true },
      { path: "job/create", element: <JobCreate />, exact: true },
      { path: "job/update/:id", element: <JobUpdate />, exact: true },
      { path: "job/applicants/:id/:title", element: <JobApplicants />, exact: true },
    ],
  },
  {
    path: "/dashboard/admin",
    element: <AdminLayout />,
    children: [
      { path: "", element: <AdminDashboard />, exact: true },
      
      { path: "users", element: <UserList />, exact: true },
      { path: "user-requests", element: <UserRequest />, exact: true },

      { path: "mentors", element: <MentorList />, exact: true },
      { path: "mentor/delete", element: <MentorDelete />, exact: true },

      { path: "companions", element: <CompanionList />, exact: true },
      { path: "companion/delete", element: <CompanionDelete />, exact: true },

      { path: "jobs", element: <JobList />, exact: true },
      { path: "job/delete", element: <JobDelete />, exact: true },
      
      { path: "projects", element: <ProjectList />, exact: true },
      { path: "project/delete", element: <ProjectDelete />, exact: true },

      { path: "hrs", element: <HrList />, exact: true },
      { path: "hr/delete", element: <HrDelete />, exact: true },

      { path: "contributors", element: <ContributorList />, exact: true },
      { path: "contributors/detail", element: <ContributorDetail />, exact: true },
      { path: "contributor/create", element: <ContributorCreate />, exact: true },
      { path: "contributor/update/:id", element: <ContributorUpdate />, exact: true },
      { path: "contributor/delete", element: <ContributorDelete />, exact: true },

      { path: "tasks", element: <TaskList />, exact: true },
      { path: "task/create", element: <TaskCreate />, exact: true },
      { path: "task/update/:id", element: <TaskUpdate />, exact: true },
      { path: "task/delete", element: <TaskDelete />, exact: true },

      { path: "contributor-tasks", element: <ContributorTaskList />, exact: true },
      { path: "contributor-task/create", element: <ContributorTaskCreate />, exact: true },
      { path: "contributor-task/update/:id", element: <ContributorTaskUpdate />, exact: true },
      { path: "contributor-task/delete", element: <ContributorTaskDelete />, exact: true },
    ],
  },
  {
    path: "/dashboard/contributor",
    element: <ContributorLayout />,
    children: [
      { path: "", element: <ContributorDashboard />, exact: true },
      { path: "contributors", element: <ContributorList />, exact: true },
      { path: "contributors/detail", element: <ContributorDetail />, exact: true },
      { path: "contributor/update/:id", element: <ContributorUpdate />, exact: true },
      { path: "contributor-tasks", element: <ContributorTaskList />, exact: true },
    ],
  },
  {
    path: "/dashboard/marketplace",
    element: <MarketplaceLayout />,
    children: [
      { path: "", element: <MarketplaceDashboard />, exact: true },
      { path: "seller/create", element: <SellerCreate />, exact: true },
      { path: "seller/update", element: <SellerUpdate />, exact: true },
      { path: "seller/detail", element: <SellerDetail />, exact: true },
      { path: "market/seller_projects", element: <SellerProjects />, exact: true },
      { path: "market/seller_projects/create/", element: <MarketplaceProjectCreate />, exact: true },
      { path: "market/seller_projects/update/:id", element: <MarketplaceProjectUpdate />, exact: true },
      { path: "market/seller_projects/detail/:id", element: <SellerProjectDetail />, exact: true },
      { path: "market/all_projects", element: <MyMarketplaceProjects />, exact: true },// Add the new route for the checkout page here:
      { path: "checkout/:id", element: <CheckoutPage />, exact: true },
      {
        path: "market/all_projects/detail/:id",
        element: <MarketplaceProjectDetail />,
        exact: true,
      },
    ]
  }
];

export default ThemeRoutes;
