import axios from "axios";
import { BASE_URL } from "../../axiosConfig";

export const eventView = async (id) => {
  try {
    const response = await axios.get(`${BASE_URL}/event/event/view/${id}/`);
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const MyEvents = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/event/my-events/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const registerEvent = async (event) => {
  try {
    const token = localStorage.getItem("accessToken");
    const response = await axios.post(
      `${BASE_URL}/event/eventRegistration/create/`,
      { event },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    console.log(response);
    return response.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};

// export const eventList = async () => {
//   try {
//     const response = await axios.get(`${BASE_URL}/event/events/`);
//     return response.data.data;
//   } catch (error) {
//     throw error;
//   }
// };

export const eventRegistrationList = async (token) => {
  try {
    const response = await axios.get(`${BASE_URL}/event/eventRegistrations/`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    throw error;
  }
};

export const editEvent = async (token, id, formData) => {
  try {
    const response = await axios.put(
      `${BASE_URL}/event/event/edit/${id}/`,
      formData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      }
    );
    console.log(response);
    return response.data.data;
  } catch (error) {
    console.log(error);
    throw error;
  }
};
