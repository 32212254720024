import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';
import axiosInstance from '../../authService';

const INITIAL_STATE = {
    contributors: [],
    contributor: {
        country: '',
        state: '',
        photo: null,
        bio: '',
        phone: '',
        git_hub: '',
        linked_in: '',
        experience: '',
        domain: '',
        skills: '',
        qualification: '',
        resume: null,
        is_deleted: false,
        is_blocked: false,
        user: ''
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations
export const fetchContributors = createAsyncThunk('contributor/fetchContributors', async () => {
    const response = await axios.get(`${BASE_URL}/contributor/contributors/`);
    return response.data.data;
});

export const createContributor = createAsyncThunk('contributor/createContributor', async ({ formData, token }, { rejectWithValue }) => {
    try {
        //console.log("form", formData)
        const response = await axiosInstance.post(`${BASE_URL}/contributor/contributor/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log(response);
        return response.data.data;
    } catch (error) {
        console.error('Error creating contributor:', error.response);
        // Pass the error to the rejectWithValue function
        return rejectWithValue(error.response?.data || { message: error.message });
    }
});

export const updateContributor = createAsyncThunk('contributor/updateContributor', async ({ id, contributorData }) => {
    const response = await axiosInstance.put(`${BASE_URL}/contributor/contributor/edit/${id}/`, contributorData);
    return response.data.data;
});

export const deleteContributor = createAsyncThunk('contributor/deleteContributor', async (id) => {
    await axiosInstance.put(`${BASE_URL}/contributor/contributor/delete/${id}/`);
    return id;
});

const contributorSlice = createSlice({
    name: 'contributor',
    initialState: INITIAL_STATE,
    reducers: {
        setCountry: (state, action) => {
            state.contributor.country = action.payload;
        },
        setState: (state, action) => {
            state.contributor.state = action.payload;
        },
        setPhoto: (state, action) => {
            state.contributor.photo = action.payload;
        },
        setBio: (state, action) => {
            state.contributor.bio = action.payload;
        },
        setPhone: (state, action) => {
            state.contributor.phone = action.payload;
        },
        setGitHub: (state, action) => {
            state.contributor.git_hub = action.payload;
        },
        setLinkedIn: (state, action) => {
            state.contributor.linked_in = action.payload;
        },
        setExperience: (state, action) => {
            state.contributor.experience = action.payload;
        },
        setDomain: (state, action) => {
            state.contributor.domain = action.payload;
        },
        setSkills: (state, action) => {
            state.contributor.skills = action.payload;
        },
        setQualification: (state, action) => {
            state.contributor.qualification = action.payload;
        },
        setResume: (state, action) => {
            state.contributor.resume = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.companion.is_deleted = action.payload;
        },
        setIsBlocked: (state, action) => {
            state.companion.is_blocked = action.payload;
        },
        setUser: (state, action) => {
            state.contributor.user = action.payload;
        },
        setContributorData: (state, action) => {
            state.contributor = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContributors.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContributors.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contributors = action.payload;
            })
            .addCase(fetchContributors.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createContributor.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createContributor.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contributors.push(action.payload);
            })
            .addCase(createContributor.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateContributor.fulfilled, (state, action) => {
                const index = state.contributors.findIndex(contributor => contributor.id === action.payload.id);
                if (index !== -1) {
                    state.contributors[index] = action.payload;
                }
            })
            .addCase(deleteContributor.fulfilled, (state, action) => {
                state.contributors = state.contributors.filter(contributor => contributor.id !== action.payload);
            });
    }
});

export const {
    setCountry,
    setState,
    setPhoto,
    setBio,
    setPhone,
    setGitHub,
    setLinkedIn,
    setExperience,
    setDomain,
    setSkills,
    setQualification,
    setResume,
    setIsDeleted,
    setIsBlocked,
    setUser,
    setContributorData
} = contributorSlice.actions;

export default contributorSlice.reducer;