import { jwtDecode } from 'jwt-decode';
import axios from 'axios';

export const isTokenExpired = (token) => {
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp < currentTime;
};

export const refreshToken = async () => {
  const refresh_token = localStorage.getItem('refreshToken');

  try {
    const response = await axios.post('https://backend.woomtech.com/api/v1/user/token/refresh/', {
      refresh: refresh_token
    });

    localStorage.setItem('accessToken', response.data.access);
    return response.data.access;
  } catch (error) {
    console.error('Failed to refresh token', error);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    throw error;
  }
};
