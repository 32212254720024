import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';
import axiosInstance from '../../authService';

const INITIAL_STATE = {
    contributor_tasks: [],
    contributor_task: {
        contributor: '',
        task: '',
        status: '',
        weightage_after_deadline: '',
        first_deadline: '',
        second_deadline: '',
        net_weightage: ''
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations
export const fetchContributorTasks = createAsyncThunk('contributor_task/fetchComstributorTask', async () => {
    const response = await axios.get(`${BASE_URL}/contributor/contributor-tasks/`);
    return response.data.data;
});

export const createContributorTask = createAsyncThunk('contributor_task/createContributorTask', async ({ formData, token }, { rejectWithValue }) => {
    try {
        //console.log("form", formData)
        const response = await axiosInstance.post(`${BASE_URL}/contributor/contributor-task/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        if (response.status === 200 && response.data.status === 200) {
            return response.data.data;
        } else {
            return rejectWithValue(response.data);
        }
    } catch (error) {
        if (error.response && error.response.data) {
            return rejectWithValue(error.response.data);
        } else {
        return rejectWithValue({
            message: "Network error or server is down. Please try again later.",
        });
        }
    }
});

export const updateContributorTask = createAsyncThunk('contributor_task/updateContributorTask', async ({ id, formData, token }, { rejectWithValue }) => {
    try {
        const response = await axiosInstance.patch(`${BASE_URL}/contributor/contributor-task/edit/${id}/`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`,
          },
        });
        
        if (response.status === 200) {
          return response.data.data;
        } else {
          return rejectWithValue(response.data);
        }
      } catch (error) {
        if (error.response && error.response.data) {
          return rejectWithValue(error.response.data);
        } else {
          return rejectWithValue({
            message: "Network error or server is down. Please try again later.",
          });
        }
      }
});

export const deleteContributorTask = createAsyncThunk('contributor_task/deleteContributorTask', async (id) => {
    await axiosInstance.put(`${BASE_URL}/contributor/contributor-task/delete/${id}/`);
    return id;
});

const contributorTaskSlice = createSlice({
    name: 'contributor_task',
    initialState: INITIAL_STATE,
    reducers: {
        setContributor: (state, action) => {
            state.contributor_task.contributor = action.payload;
        },
        setTask: (state, action) => {
            state.contributor_task.task = action.payload;
        },
        setPhoto: (state, action) => {
            state.contributor_task.photo = action.payload;
        },
        setStatus: (state, action) => {
            state.contributor_task.satus = action.payload;
        },
        setWeightageAfterDeadline: (state, action) => {
            state.contributor_task.weightage_after_deadline = action.payload;
        },
        setFirstDeadline: (state, action) => {
            state.contributor_task.first_deadline = action.payload;
        },
        setSecondDeadline: (state, action) => {
            state.contributor_task.second_deadline = action.payload;
        },
        setNetWeightage: (state, action) => {
            state.contributor_task.net_weightage = action.payload;
        },
        setDomain: (state, action) => {
            state.contributor_task.domain = action.payload;
        },
        setSkills: (state, action) => {
            state.contributor_task.skills = action.payload;
        },
        setQualification: (state, action) => {
            state.contributor_task.qualification = action.payload;
        },
        setResume: (state, action) => {
            state.contributor_task.resume = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.contributor_task.is_deleted = action.payload;
        },
        setIsBlocked: (state, action) => {
            state.contributor_task.is_blocked = action.payload;
        },
        setUser: (state, action) => {
            state.contributor_task.user = action.payload;
        },
        setContributorTaskData: (state, action) => {
            state.contributor_task = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchContributorTasks.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchContributorTasks.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contributor_tasks = action.payload;
            })
            .addCase(fetchContributorTasks.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createContributorTask.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createContributorTask.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.contributor_tasks.push(action.payload);
            })
            .addCase(createContributorTask.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateContributorTask.fulfilled, (state, action) => {
                const index = state.contributor_tasks.findIndex(contributor_task => contributor_task.id === action.payload.id);
                if (index !== -1) {
                    state.contributor_tasks[index] = action.payload;
                }
            })
            .addCase(deleteContributorTask.fulfilled, (state, action) => {
                state.contributor_tasks = state.contributor_tasks.filter(contributor_task => contributor_task.id !== action.payload);
            });
    }
});

export const {
    setContributor,
    setTask,
    setStatus,
    setWeightageAfterDeadline,
    setFirstDeadline,
    setSecondDeadline,
    setNetWeightage,
    setContributorTaskData
} = contributorTaskSlice.actions;

export default contributorTaskSlice.reducer;
