import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';
import { MyEvents } from './event_api';

const INITIAL_STATE = {
    events: [],
    event: {
        title: '',
        description: '',
        organizer: null,
        poster:null,
        event_date: '',
        event_time: '',
        duration: '',
        venue: '',
        url: '',
        is_online: false,
        is_completed: false,
        is_deleted: false
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations
export const fetchMyEvents = createAsyncThunk('event/fetchUserEvents', async () => {
    const token = localStorage.getItem('accessToken');
    const response = await MyEvents(token);
    return response;
});

export const fetchEvents = createAsyncThunk('event/fetchEvents', async () =>{
    const response= await axios.get(`${BASE_URL}/event/events/`)
    return response.data.data
})


export const createEvent = createAsyncThunk('event/createEvent', async ({ formData, token }) => {
    try {
        const response = await axios.post(`${BASE_URL}/event/event/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        console.log("re",response);
        return response.data.data;
    } catch (error) {
        console.error('Error creating event:', error.response);
        throw error;
    }
});

export const updateEvent = createAsyncThunk('event/updateEvent', async ({ id, eventData }) => {
    const response = await axios.put(`${BASE_URL}/event/event/edit/${id}/`, eventData);
    return response.data.data;
});

export const deleteEvent = createAsyncThunk('event/deleteEvent', async (id) => {
    await axios.put(`${BASE_URL}/event/event/delete/${id}/`);
    return id;
});

export const cancelEventRegister = createAsyncThunk('event/cacelEventRegister', async (id) => {
    await axios.put(`${BASE_URL}/event/eventRegistration/cancel/${id}/`);
    return id;
});

const eventSlice = createSlice({
    name: 'event',
    initialState: INITIAL_STATE,
    reducers: {
        setTitle: (state, action) => {
            state.event.title = action.payload;
        },
        setDescription: (state, action) => {
            state.event.description = action.payload;
        },
        setOrganizer: (state, action) => {
            state.event.organizer = action.payload;
        },
        setEventDate: (state, action) => {
            state.event.event_date = action.payload;
        },
        setDuration: (state, action) => {
            state.event.duration = action.payload;
        },
        setVenue: (state, action) => {
            state.event.venue = action.payload;
        },
        setIsOnline: (state, action) => {
            state.event.is_online = action.payload;
        },
        setUrl: (state, action) => {
            state.event.url = action.payload;
        },
        setIsCompleted: (state, action) => {
            state.event.is_completed = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.event.is_deleted = action.payload;
        },
        setEventData: (state, action) => {
            state.event = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = action.payload;
            })
            .addCase(fetchEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(fetchMyEvents.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchMyEvents.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events = action.payload;
            })
            .addCase(fetchMyEvents.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createEvent.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createEvent.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.events.push(action.payload);
            })
            .addCase(createEvent.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateEvent.fulfilled, (state, action) => {
                const index = state.events.findIndex(event => event.id === action.payload.id);
                if (index !== -1) {
                    state.events[index] = action.payload;
                }
            })
            .addCase(deleteEvent.fulfilled, (state, action) => {
                state.events = state.events.filter(event => event.id !== action.payload);
            })
            .addCase(cancelEventRegister.fulfilled, (state, action) => {
                state.events = state.events.filter(event => event.id !== action.payload);
            });
    }
});

export const {
    setTitle,
    setDescription,
    setOrganizer,
    setEventDate,
    setDuration,
    setVenue,
    setIsOnline,
    setUrl,
    setIsCompleted,
    setIsDeleted,
    setEventData
} = eventSlice.actions;

export default eventSlice.reducer;