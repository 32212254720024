import React from 'react';
import { useRoutes } from "react-router-dom";
import Themeroutes from "./routes/Router";
import { Helmet } from "react-helmet";

const App = () => {
  const routing = useRoutes(Themeroutes);

  return (
    <div className="dark">
      <Helmet>
        {/* Basic Meta Tags */}
        <title>Tech Women Community and Project Marketplace</title>
        <meta name="description" content="A platform to empower women in tech with collaboration, mentorship, and career growth opportunities." />
        <meta name="keywords" content="woman community, software project marketplace, women software developer, tech women community, tech communities in kerala, project collaboration" />
        <meta name="robots" content="index, follow" />
        <link rel="canonical" href="https://woomtech.com/" />

        {/* Open Graph Meta Tags */}
        <meta property="og:title" content="woomtech - A Product Of Women Tech Community" />
        <meta property="og:description" content="A platform to empower women in tech with collaboration, mentorship, and career growth opportunities." />
        <meta property="og:image" content="https://woomtech.com/woomtech-logo.jpg" />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="630" />
        <meta property="og:url" content="https://woomtech.com" />
        <meta property="og:type" content="website" />
        <meta name="google-site-verification" content="haa-tvwdTuYDpYyS_cxJ4K-OROatKx0lxP3ahJ0USBU" />

        {/* Twitter Card Meta Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="woomtech - A Product Of Women Tech Community" />
        <meta name="twitter:description" content="A platform designed for women in tech, offering project collaboration, mentorship, and career growth opportunities. Connect with a vibrant tech women community, explore software project marketplaces, and engage with tech communities in Kerala to empower your career." />
        <meta name="twitter:image" content="https://woomtech.com/woomtech-logo.jpg" />
        <meta name="twitter:url" content="https://woomtech.com" />

        {/* Favicon and Apple Touch Icons */}
        <link rel="icon" href="%PUBLIC_URL%/woomtech-logo.jpg" />
        <link rel="apple-touch-icon" sizes="180x180" href="%PUBLIC_URL%/woomtech-logo.jpg" />

        {/* Web Fonts */}
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap" />

        {/* Font Awesome CDN */}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css" />
        {/* Google tag (gtag.js) */}
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-X0RFL82WB9"></script>

        
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag() { dataLayer.push(arguments); }
            gtag('js', new Date());
            gtag('config', 'G-X0RFL82WB9');
          `}
        </script>
        
        {/* JSON-LD Structured Data */}
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Tech Women Community and Project Marketplace",
            "url": "https://woomtech.com/",
            "description": "A platform designed for women in tech, offering project collaboration, mentorship, and career growth opportunities. Connect with a vibrant tech women community, explore software project marketplaces, and engage with tech communities in Kerala to empower your career.",
            "keywords": "woman community, software project marketplace, women software developer, tech women community, tech communities in kerala, project collaboration",
            "inLanguage": "en",
            "potentialAction": {
              "@type": "SearchAction",
              "target": "https://woomtech.com/search?q={search_term_string}",
              "query-input": "required name=search_term_string"
            },
            "author": {
              "@type": "Organization",
              "name": "Your Organization Name",
              "url": "https://woomtech.com/"
            },
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "https://woomtech.com/"
            }
          })}
        </script>
      </Helmet>

      {routing}
    </div>
  );
};

export default App;