import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';

const INITIAL_STATE = {
    comments: [],
    comment: {
        project: '',
        companion: '',  // Updated field
        image: null,
        comment: '',
        file: null
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations

// Fetch comments specific to a project
export const fetchComments = createAsyncThunk('comment/fetchComments', async (projectId) => {
    try {
        const response = await axios.get(`${BASE_URL}/project/${projectId}/comments/`);
        return response.data.data; // Ensure data structure matches API response
    } catch (error) {
        console.error('Error fetching comments:', error.response);
        throw error;
    }
});

export const createComment = createAsyncThunk('comment/createComment', async ({ formData, token }) => {
    try {
        const response = await axios.post(`${BASE_URL}/project/comment/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        return response.data.data; // Ensure data structure matches API response
    } catch (error) {
        console.error('Error creating comment:', error.response);
        throw error;
    }
});

export const updateComment = createAsyncThunk('comment/updateComment', async ({ id, commentData, token }) => {
    try {
        const response = await axios.put(`${BASE_URL}/project/comment/edit/${id}/`, commentData, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return response.data.data; // Ensure data structure matches API response
    } catch (error) {
        console.error('Error updating comment:', error.response);
        throw error;
    }
});

export const deleteComment = createAsyncThunk('comment/deleteComment', async ({ id, token }) => {
    try {
        await axios.delete(`${BASE_URL}/project/comment/delete/${id}/`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        return id; // Return comment id for filtering
    } catch (error) {
        console.error('Error deleting comment:', error.response);
        throw error;
    }
});

const commentSlice = createSlice({
    name: 'comment',
    initialState: INITIAL_STATE,
    reducers: {
        setProject: (state, action) => {
            state.comment.project = action.payload;
        },
        setCompanion: (state, action) => {  // Updated field
            state.comment.companion = action.payload;
        },
        setImage: (state, action) => {
            state.comment.image = action.payload;
        },
        setComment: (state, action) => {
            state.comment.comment = action.payload;
        },
        setFile: (state, action) => {
            state.comment.file = action.payload;
        },
        setCommentData: (state, action) => {
            state.comment = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchComments.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchComments.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.comments = action.payload; // Set fetched comments
            })
            .addCase(fetchComments.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message; // Set error message
            })
            .addCase(createComment.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createComment.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.comments.push(action.payload); // Add new comment
            })
            .addCase(createComment.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message; // Set error message
            })
            .addCase(updateComment.fulfilled, (state, action) => {
                const index = state.comments.findIndex(comment => comment.id === action.payload.id);
                if (index !== -1) {
                    state.comments[index] = action.payload; // Update existing comment
                }
            })
            .addCase(deleteComment.fulfilled, (state, action) => {
                state.comments = state.comments.filter(comment => comment.id !== action.payload); // Remove deleted comment
            });
    }
});

export const {
    setProject,
    setCompanion,  // Updated field
    setImage,
    setComment,
    setFile,
    setCommentData
} = commentSlice.actions;

export default commentSlice.reducer;
