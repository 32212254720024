import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import axiosInstance from '../../authService'; // Adjust import path as needed
import { BASE_URL } from '../../axiosConfig'; // Adjust import path as needed

const INITIAL_STATE = {
    projects : [],
    project: {
        name: "",
        description: "",
        start_date: "",
        end_date: "",
        leader: "", // Assuming leader will be set dynamically based on user
        team: [], // Array to store companion IDs
        mentor: [], // Array to store mentor IDs
        file: null, // File upload field
        is_active: true, // Default to true
        is_deleted: false // Default to false
    },
    status: 'idle',
    error: null
};

export const sendProjectRequest = createAsyncThunk(
    'project/sendProjectRequest',
    async ({ projectId, receiverId }, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`${BASE_URL}/project/request/create/`, {
            project: projectId,
            receiver: receiverId, // Use dynamic receiver ID
            });
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
        }
    );
    

// Async actions for CRUD operations
export const fetchProjects = createAsyncThunk('project/fetchProjects', async (keywords = '') => {
    const response = await axios.get(`${BASE_URL}/project/projects/`, {
        params: { keywords }
    });
    console.log(response.data);
    
    return response.data.data;
});


export const createProject = createAsyncThunk('project/createProject', async ({ formData }) => {
    try {
        const response = await axiosInstance.post(`${BASE_URL}/project/project/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error creating project:', error.response);
        throw error;
    }
});

export const updateProject = createAsyncThunk('project/updateProject', async ({ id, formData }) => {
    try {
        const response = await axiosInstance.patch(`${BASE_URL}/project/project/edit/${id}/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return response.data.data;
    } catch (error) {
        console.error('Error updating project:', error.response);
        throw error;
    }
});

export const deleteProject = createAsyncThunk('project/deleteProject', async (id) => {
    await axiosInstance.put(`${BASE_URL}/project/project/delete/${id}/`);
    return id;
});

const projectSlice = createSlice({
    name: 'project',
    initialState: INITIAL_STATE,
    reducers: {
        setName: (state, action) => {
            state.project.name = action.payload;
        },
        setDescription: (state, action) => {
            state.project.description = action.payload;
        },
        setStartDate: (state, action) => {
            state.project.start_date = action.payload;
        },
        setEndDate: (state, action) => {
            state.project.end_date = action.payload;
        },
        setLeader: (state, action) => {
            state.project.leader = action.payload;
        },
        setTeam: (state, action) => {
            state.project.team = action.payload;
        },
        setMentor: (state, action) => {
            state.project.mentor = action.payload;
        },
        setFile: (state, action) => {
            state.project.file = action.payload;
        },
        setIsActive: (state, action) => {
            state.project.is_active = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.project.is_deleted = action.payload;
        },
        setProjectData: (state, action) => {
            state.project = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchProjects.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchProjects.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.projects = action.payload;
            })
            .addCase(fetchProjects.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createProject.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createProject.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.projects.push(action.payload);
            })
            .addCase(createProject.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateProject.fulfilled, (state, action) => {
                const index = state.projects.findIndex(project => project.id === action.payload.id);
                if (index !== -1) {
                    state.projects[index] = action.payload;
                }
            })
            .addCase(deleteProject.fulfilled, (state, action) => {
                state.projects = state.projects.filter(project => project.id !== action.payload);
            });
    }
});

export const {
    setName,
    setDescription,
    setStartDate,
    setEndDate,
    setLeader,
    setTeam,
    setMentor,
    setFile,
    setIsActive,
    setIsDeleted,
    setProjectData
} = projectSlice.actions;

export default projectSlice.reducer;
