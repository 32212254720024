import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig';

const INITIAL_STATE = {
    hrs: [],
    hr: {
        company_name: "",
        country: "",
        state: "",
        phone: "",
        linkedin: "",
        photo: "",
        rating: 0.0,
        is_deleted: false,
        is_blocked: false,
        user: ''
    },
    status: 'idle',
    error: null
};

// Async actions for CRUD operations
export const fetchHrs = createAsyncThunk('hr/fetchHrs', async () => {
    const response = await axios.get(`${BASE_URL}/hr/hrs/`);
    return response.data.data;
});

export const createHr = createAsyncThunk('hr/createHr', async ({formData, token}) => {
    try {
        //console.log("form", formData)
        const response = await axios.post(`${BASE_URL}/hr/hr/create/`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${token}`,
            },
        });
        //console.log(response);
        return response.data.data;
    } catch (error) {
        console.error('Error creating hr:', error.response);
        throw error;
    }
});

export const updateHr = createAsyncThunk('hr/updateHr', async ({ id, hrData }) => {
    const response = await axios.put(`${BASE_URL}/hr/hr/edit/${id}/`, hrData);
    return response.data.data;
});

export const deleteHr = createAsyncThunk('hr/deleteHr', async (id) => {
    await axios.put(`${BASE_URL}/hr/hr/delete/${id}/`);
    return id;
});

const hrSlice = createSlice({
    name: 'hr',
    initialState: INITIAL_STATE,
    reducers: {
        setCountry: (state, action) => {
            state.hr.country = action.payload;
        },
        setState: (state, action) => {
            state.hr.state = action.payload;
        },
        setPhoto: (state, action) => {
            state.hr.photo = action.payload;
        },
        setBio: (state, action) => {
            state.hr.bio = action.payload;
        },
        setPhone: (state, action) => {
            state.hr.phone = action.payload;
        },
        setGitHub: (state, action) => {
            state.hr.git_hub = action.payload;
        },
        setLinkedIn: (state, action) => {
            state.hr.linked_in = action.payload;
        },
        setExperience: (state, action) => {
            state.hr.experience = action.payload;
        },
        setDomain: (state, action) => {
            state.hr.domain = action.payload;
        },
        setSkills: (state, action) => {
            state.hr.skills = action.payload;
        },
        setQualification: (state, action) => {
            state.hr.qualification = action.payload;
        },
        setResume: (state, action) => {
            state.hr.resume = action.payload;
        },
        setIsDeleted: (state, action) => {
            state.hr.is_deleted = action.payload;
        },
        setIsBlocked: (state, action) => {
            state.hr.is_blocked = action.payload;
        },
        setUser: (state, action) => {
            state.hr.user = action.payload;
        },
        sethrData: (state, action) => {
            state.hr = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchHrs.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(fetchHrs.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrs = action.payload;
            })
            .addCase(fetchHrs.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(createHr.pending, (state) => {
                state.status = 'loading';
            })
            .addCase(createHr.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.hrs.push(action.payload);
            })
            .addCase(createHr.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.error.message;
            })
            .addCase(updateHr.fulfilled, (state, action) => {
                const index = state.hrs.findIndex(hr => hr.id === action.payload.id);
                if (index !== -1) {
                    state.hrs[index] = action.payload;
                }
            })
            .addCase(deleteHr.fulfilled, (state, action) => {
                state.hrs = state.hrs.filter(hr => hr.id !== action.payload);
            });
    }
});

export const {
    setCountry,
    setState,
    setPhoto,
    setPhone,
    setLinkedIn,
    setIsDeleted,
    setIsBlocked,
    setUser,
    sethrData
} = hrSlice.actions;

export default hrSlice.reducer;
