import axios from 'axios';
import { refreshToken, isTokenExpired } from './tokenUtil';
import { showAlert } from './utils'; // Utility function for alerts

const axiosInstance = axios.create({
  baseURL: 'http://127.0.0.1:8000/api/v1/', // Your API base URL
  // Other configurations
});

axiosInstance.interceptors.request.use(
  async (config) => {
    const accessToken = localStorage.getItem('accessToken');

    if (accessToken && isTokenExpired(accessToken)) {
      try {
        const newAccessToken = await refreshToken();
        config.headers.Authorization = `Bearer ${newAccessToken}`;
        localStorage.setItem('accessToken', newAccessToken); 
      } catch (error) {
        console.error('Failed to refresh token');
        showAlert('Session Expired', 'Please log in again.', 'error');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        window.location.href = '/login'; // Redirect to login page on token refresh failure
        throw error;
      }
    } else {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;