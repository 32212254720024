import Swal from 'sweetalert2';

// Function to show alerts using SweetAlert2
export const showAlert = (title, text, icon = 'info') => {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true, // Show cancel button
      confirmButtonText: 'OK',
      cancelButtonText: 'Cancel', // Custom cancel button text
    });
  };


  export const showProfileAlert = (title, text, icon = 'info') => {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: true, // Show cancel button
      confirmButtonText: 'OK',
    });
  };

