import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../authService.js';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig.js';

const INITIAL_STATE = {
  tasks: [],
  task: {
    name: "",
    weightage: "",
    unit: ""
  },
  status: 'idle',
  error: null
};

export const fetchTasks = createAsyncThunk('contributor/fetchTasks', async () => {
  const response = await axiosInstance.get(`${BASE_URL}/contributor/tasks/`);    
  return response.data.data;
});

export const createTask = createAsyncThunk(
  'task/createTask',
  async ({ formData, token }, { rejectWithValue }) => { // Ensure { rejectWithValue } is properly passed
    try {
      const response = await axiosInstance.post(`${BASE_URL}/contributor/task/create/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (response.status === 200 && response.data.status === 200) {
        return response.data.data;
      } else {
        // If the API returns a custom error message
        return rejectWithValue(response.data);
      }
    } catch (error) {
      // If there's an actual error from the API
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        // General network or unexpected error
        return rejectWithValue({
          message: "Network error or server is down. Please try again later.",
        });
      }
    }
  }
);

export const updateTask = createAsyncThunk(
  'task/updateTask',
  async ({ id, formData, token }, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.put(`${BASE_URL}/contributor/task/edit/${id}/`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });
      
      if (response.status === 200) {
        return response.data.data;
      } else {
        return rejectWithValue(response.data);
      }
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      } else {
        return rejectWithValue({
          message: "Network error or server is down. Please try again later.",
        });
      }
    }
  }
);

export const deleteTask = createAsyncThunk('task/deleteTask', async (id) => {
  await axiosInstance.put(`${BASE_URL}/contributor/task/delete/${id}/`);
  return id;
});



const taskSlice = createSlice({
  name: 'task',
  initialState: INITIAL_STATE,
  reducers: {
    setName: (state, action) => {
      state.task.name = action.payload;
    },
    setWeightage: (state, action) => {
      state.task.weightage = action.payload;
    },
    setUnit: (state, action) => {
      state.task.unit = action.payload;
    },
    setTaskData: (state, action) => {
      state.task = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasks = action.payload;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createTask.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createTask.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.tasks.push(action.payload);
      })
      .addCase(createTask.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateTask.fulfilled, (state, action) => {
        const updatedTask = action.payload;
        const index = state.tasks.findIndex(task => task.id === updatedTask.id);
        if (index !== -1) {
          state.tasks[index] = updatedTask;
        }
      })
      .addCase(deleteTask.fulfilled, (state, action) => {
        state.tasks = state.tasks.filter(task => task.id !== action.payload);
      })
  }
});

export const {
  setName,
  setWeightage,
  setUnit,
  setTaskData
} = taskSlice.actions;

export default taskSlice.reducer;