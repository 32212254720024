import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosInstance from '../../authService.js';
import axios from 'axios';
import { BASE_URL } from '../../axiosConfig.js';

const INITIAL_STATE = {
  jobs: [],
  job: {
    title: "",
    description: "",
    salary_from: "",
    salary_to: "",
    github: "",
    skills: "",
    experience: 0,
    company: "",
    location:""
  },
  status: 'idle',
  error: null
};

export const fetchJobs = createAsyncThunk('job/fetchJobs', async (keywords = '') => {
  const response = await axiosInstance.get(`${BASE_URL}/hr/job-posts/`,{
    params: { keywords }
  });
  return response.data.data;
});

export const fetchAllJobs = createAsyncThunk('job/fetchJobs', async (keywords = '') => {
  const response = await axios.get(`${BASE_URL}/hr/all-job-posts/`, {
    params: { keywords }
  });
  return response.data.data;
});

export const createJob = createAsyncThunk('job/createJob', async ({ formData, token }) => {
  try {
    const response = await axiosInstance.post(`${BASE_URL}/hr/job-post/create/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error creating job:', error.response);
    throw error;
  }
});

export const updateJob = createAsyncThunk('job/updateJob', async ({ id, formData, token }) => {
  try {
    const response = await axiosInstance.put(`${BASE_URL}/hr/job-post/edit${id}/`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`,
      },
    });
    return response.data.data;
  } catch (error) {
    console.error('Error updating job:', error.response);
    throw error;
  }
});

export const deleteJob = createAsyncThunk('job/deleteJob', async (id) => {
  await axiosInstance.put(`${BASE_URL}/hr/job-post/delete/${id}/`);
  return id;
});



const jobSlice = createSlice({
  name: 'job',
  initialState: INITIAL_STATE,
  reducers: {
    setTitle: (state, action) => {
      state.job.title = action.payload;
    },
    setDescription: (state, action) => {
      state.job.description = action.payload;
    },
    setSalaryFrom: (state, action) => {
      state.job.salary_from = action.payload;
    },
    setSalaryTo: (state, action) => {
      state.job.salary_to = action.payload;
    },
    setExperience: (state, action) => {
      state.job.experience = action.payload;
    },
    setSkills: (state, action) => {
      state.job.skills = action.payload;
    },
    setCompany: (state, action) => {
      state.job.company = action.payload;
    },
    setLocation: (state, action) => {
      state.job.location = action.payload;
    },
    setJobData: (state, action) => {
      state.job = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs = action.payload;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(createJob.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(createJob.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.jobs.push(action.payload);
      })
      .addCase(createJob.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(updateJob.fulfilled, (state, action) => {
        const updatedJob = action.payload;
        const index = state.jobs.findIndex(job => job.id === updatedJob.id);
        if (index !== -1) {
          state.jobs[index] = updatedJob;
        }
      })
      .addCase(deleteJob.fulfilled, (state, action) => {
        state.jobs = state.jobs.filter(job => job.id !== action.payload);
      })
  }
});

export const {
  setTitle,
  setDescription,
  setSalaryFrom,
  setSalaryTo,
  setExperience,
  setSkills,
  setCompany,
  setLocation,
  setJobData
} = jobSlice.actions;

export default jobSlice.reducer;