import { configureStore } from "@reduxjs/toolkit";
import mentorListReducer from "../features/mentor/mentorSlice";
import companionListReducer from "../features/companion/companionSlice";
import eventListReducer from "../features/events/EventSlice"
import userReducer from "../features/user/userSlice";
import projectReducer from "../features/project/projectSlice";
import commentReducer from "../features/project/commentSlice";
import jobListReducer from "../features/job/jobSlice";
import hrListReducer from "../features/hr/hrSlice";
import contributorListReducer from "../features/contributor/contributorSlice";
import marketplaceReducer from "../features/market/marketSlice";
import sellerReducer from "../features/seller/sellerSlice";
import taskListReducer from "../features/task/taskSlice";
import contributorTaskListReducer from "../features/contributor_task/contributorTaskSlice";

const store = configureStore({
  reducer: {
    mentors: mentorListReducer,
    companions: companionListReducer,
    events: eventListReducer,

    user: userReducer,

    project: projectReducer,
    comment: commentReducer,
    job: jobListReducer,
    hr: hrListReducer,
    contributors: contributorListReducer,

    marketplace: marketplaceReducer,
    seller: sellerReducer,

    tasks: taskListReducer,
    contributor_tasks: contributorTaskListReducer
  },
});

export default store;
